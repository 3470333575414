import axios from "axios";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Input, Modal } from "../../../components";
import { useSnapShotUser } from "../../../utilities/helper";

function SnapShotLoginPage() {
  const { user, setUser } = useSnapShotUser();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const onSubmit = (e) => {
    e.preventDefault();
    if (email && password) {
      setIsSubmitting(true);
      axios.post("/api/rafa/auth", { email, password })
        .then((result) => {
          setUser(result.data);
          const { from } = location.state || { from: { pathname: "/tasks-overview" } };
          history.replace(from);
        })
        .catch((error) => {
          setError('Wrong username and password. Please try again.');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      alert("Please enter a valid username and password");
    }
  };

  const onInputChange = (e, cb) => {
    const { value } = e.target;
    return cb(value);
  };

  return (
    <>
      <Helmet>
        <title>Snapshot</title>
      </Helmet>
      <div className="container mt-4">
        <div style={{ width: '100%', textAlign: 'center', marginBottom: 40, marginTop: 40 }}>
          <a href="/">
            <img src="/snapshot_logo.png" alt="logo" style={{ width: "50%", cursor: "pointer" }} />
          </a>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <form className="col-6 mx-auto mt-3" onSubmit={onSubmit}>
          <Input title="Username" type="email" onChange={(e) => onInputChange(e, setEmail)} />
          <Input
            type="password"
            title="Password"
            autoComplete="password"
            onChange={(e) => onInputChange(e, setPassword)}
          />
          <div className="row">
            <div className="col-sm-4"></div>
            <div className="col-sm-8">
              <div className="row justify-content-center">
                <Button type="submit" title="Login" className="px-4 bg-primary" disabled={isSubmitting} />
              </div>
              <div className="row justify-content-center">
                <a href="#" onClick={() => { setShowModal(true) }} className="p-2">Forgot password?</a>
              </div>
            </div>
          </div>
        </form>
        <Modal
          visible={showModal}
          setVisible={() => {
            setShowModal(false);
          }}
          title=""
        >
          <div className="text-center mt-3">
            <h4>Please reach out to your administrator to get your password reset.</h4>
          </div>

          <div className="row justify-content-center mt-5">
            <Button type="button" title="OK" className="px-4 bg-primary" onPress={() => setShowModal(false)} />
          </div>

        </Modal>
      </div>
    </>
  );
}

export default SnapShotLoginPage;
